import dummyWaveformImg from '~/assets/dummy_waveform.jpg'
import UploadableAsset from './UploadableAsset'

// TODO: all thumbnails should be provided by server? This dummy should be removed from repo

/**
 * @memberOf Assets
 * @extends {UploadableAsset}
 */
class AudioAsset extends UploadableAsset {

  constructor(data = {}) {
    const {
      thumbnail = dummyWaveformImg,
      ...rest
    } = data

    super({
      ...data,
      thumbnail,
    })

    // ---

    const {
      url = null,
      fadeInDuration = 0,
      fadeOutDuration = 0,
      categoryId = null,
      moodId = null,
    } = rest

    this._url = url
    this._fadeInDuration = fadeInDuration
    this._fadeOutDuration = fadeOutDuration
    this._categoryId = categoryId
    this._moodId = moodId
  }

  // ---

  /**
   * @returns {string|null}
   */
  get url() {
    return this._url
  }

  // ---
  /**
   * @returns {number} 100-nanoseconds units
   */
  get fadeInDuration() {
    return this._fadeInDuration
  }

  /**
   * @param {number} duration
   */
  set fadeInDuration(duration) {
    this._fadeInDuration = duration
  }

  // ---

  /**
   * @returns {number} 100-nanoseconds units
   */
  get fadeOutDuration() {
    return this._fadeOutDuration
  }

  /**
   * @param {number} duration
   */
  set fadeOutDuration(duration) {
    this._fadeOutDuration = duration
  }

  /**
   * @returns {string}
   */
  get categoryId() {
    return this._categoryId
  }

  /**
   * @returns {string}
   */
  get moodId() {
    return this._moodId
  }

}

export default AudioAsset
