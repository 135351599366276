import { DEFAULT_IMAGE_DURATION } from '~/constant'
import { TEXT_POSITION } from '~/enums'
import { assignPatch } from '~/hooks'
import UploadableAsset from './UploadableAsset'

/**
 * @memberOf Assets
 * @extends {UploadableAsset}
 */
class ImageAsset extends UploadableAsset {

  constructor(data = {}) {
    const { width = 250, height = 150, settings = {}, userName, userWebPage } = data

    const {
      size = {
        width,
        height,
      },
      flip = {
        horizontal: false,
        vertical: false,
      },
      rotation = 0,
      opacity = 100,
      position = TEXT_POSITION.MIDDLE_CENTER,
      offset = { top: null, bottom: null, left: null, right: null },
      outlineColor = { r: 0, g: 0, b: 0, a: 1 },
      originalSize,
      outlineWidth = 0,
      shadowColor = { r: 255, g: 255, b: 255, a: 1 },
      shadowBlur = 0,
      keepAspectRatio = true,
      aspectRatio,
      relativeSize = { width: null, height: null },
    } = settings

    const {
      url = null,
      duration = DEFAULT_IMAGE_DURATION,
      ...rest
    } = data

    super({ ...rest, duration })

    this._author = {
      name: userName,
      url: userWebPage,
    }

    this._url = url
    this._settings = {
      size,
      flip,
      rotation,
      opacity,
      position,
      offset,
      outlineColor,
      originalSize,
      outlineWidth,
      shadowColor,
      shadowBlur,
      keepAspectRatio,
      aspectRatio,
      relativeSize,
    }

    this._maxDuration = Infinity
  }

  /**
   * @returns {string|null}
   */
  get url() {
    return this._url
  }

  /**
   * @returns {object}
   */
  get settings() {
    return this._settings
  }

  /**
   * Same behavior  as `setState` in React:
   * assigned value is merged over current one.
   *
   * @param {object} patch
   */
  set settings(patch) {
    this._settings = assignPatch(this._settings, patch)
  }

  /**
   * @returns {object}
   */
  get author() {
    return this._author
  }

  /**
  * @returns {string|null}
  */
  get fileId() {
    return super.fileId
  }

  /**
   * ImageAsset allows to assign related file later
   * (after all configured settings are uploaded and saved)
   *
   * @param {string} id
   */
  set fileId(id) {
    this._fileId = id
  }

  // eslint-disable-next-line class-methods-use-this
  get canHaveTransition() {
    return true
  }

}

export default ImageAsset
